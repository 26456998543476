<template>

<!-- 校园管理  历史数据页面 -->
  <div class="container">
    <div class="header-container">
      <div class="seek-container">
        <el-select v-show="!showClass" clearable v-model="getClassId" @change="getGreadClass" filterable placeholder="请选择年级班级">
          <el-option
            v-for="(item, index) in getNewGradeClassList"
            :key="index"
            :label="item.label"
            :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.grade }}</span>
          </el-option>
        </el-select>
        <el-select v-show="showClass" @change="editClass" v-model="getClassId" placeholder="请选择">
          <el-option
            v-for="item in classList"
            :key="item.classId"
            :label="item.className"
            :value="item.classId">
          </el-option>
        </el-select>
        <div class="select-section">
          <el-input maxlength="11" placeholder="请输入学生姓名或手机号码" v-model="getSelectData"></el-input>
          <el-button @click="geySelect">搜索</el-button>
          <el-button @click="getNewData">重置</el-button>
        </div>
      </div>
    </div>
    <div class="body-container">
      <el-table
        :data="childrenList"
        :header-cell-style="{textAlign: 'center'}"
        style="width: 100%">
        <el-table-column
          label="序号"
          width="80">
          <template slot-scope="scope">
            <p class="index" style="width: 100%; text-align: center;">
              {{scope.$index + 1}}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="grade"
          label="年级">
        </el-table-column>
        <el-table-column
          prop="className"
          label="班级">
        </el-table-column>
        <el-table-column
          prop="childrenName"
          label="姓名">
        </el-table-column>
         <el-table-column
          prop="phone"
          label="手机号码"
          width="120">
        </el-table-column>
        <el-table-column
          prop="sex"
          label="性别">
        </el-table-column>
        <el-table-column
          :formatter="visionData1"
          label="裸眼视力(右/左)"
          width="150">
        </el-table-column>
         <el-table-column
          :formatter="visionData2"
          label="戴镜视力(右/左)"
          width="150">
        </el-table-column>
         <el-table-column
          prop="refractiveRightDs"
          width="150"
          label="屈光_右眼球镜DS">
        </el-table-column>
        <el-table-column
          prop="refractiveRightDc"
          width="150"
          label="屈光_右眼柱镜DC">
        </el-table-column>
        <el-table-column
          prop="refractiveLeftDs"
          width="150"
          label="屈光_左眼球镜DS">
        </el-table-column>
        <el-table-column
          prop="refractiveLeftDc"
          width="150"
          label="屈光_左眼柱镜DC">
        </el-table-column>
        <el-table-column
          prop="idCard"
          width="200"
          label="身份证号">
        </el-table-column>
        <el-table-column
          prop="birthday"
          width="200"
          label="出生日期">
        </el-table-column>
        <el-table-column
          prop="studentId"
          label="学号">
        </el-table-column>
         <el-table-column
          prop="remark"
          show-overflow-tooltip
          label="备注">
        </el-table-column>
        <el-table-column
          prop="tag"
          label="是否异常">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.visionIsNormal !== 0 ? 'danger' : 'primary'"
              disable-transitions>{{scope.row.state}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="schoolStatus"
          label="学生状态">
        </el-table-column>
        schoolStatus
        <!-- <el-table-column
        label="政府名称/机构名称/学校名称/学校班级"
        >
        </el-table-column> -->
        <el-table-column label="设置">
          <template slot-scope="scope">
            <el-button
             @click="recoverShow(scope.row.childrenId)"
              size="mini"
              type="text"
              >恢复</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-container">
      <pagination :current-page="show.startPage" :total="show.totalPage"  @currentChange="editStartPage" @sizeChange="sizeChange"></pagination>
    </div>
    <el-dialog
      title="恢复所属班级"
      :visible.sync="dialogVisible"
      width="30%">
      <div>
        <el-select clearable v-model="recoverClassId" filterable placeholder="请选择年级班级">
          <el-option
            v-for="(item, index) in getNewGradeClassList"
            :key="index"
            :label="item.label"
            :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.grade }}</span>
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="recover">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import storage from '@/globals/storage/index.js'
import studentService from '@/globals/service/management/student.js'
import GradeClassService from '@/globals/service/management/GradeClass.js'
import newDate from '@/utils/newDate.js'

export default {
  data () {
    return {
      // 恢复的学生id
      showRecoverId: null,
      // 恢复的班级
      recoverClassId: null,
      dialogVisible: false,
      getGreadId: '',
      getNewGradeClassList: '',
      getSelectData: '',
      getClassId: null,
      classList: [],
      showClass: true,
      childrenList: [],
      show: {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
    }
  },
  created () {
    this.getClassList()
    this.getClassGrade()
  },
  methods: {
    visionData1 (row) {
      if (row.leftVisionData === '-' && row.rightVisionData === '-') {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.leftVisionData) {
          left = row.leftVisionData
        }
        if (row.rightVisionData) {
          right = row.rightVisionData
        }
        return right + ' / ' + left
      }
    },
    visionData2 (row) {
      if (row.wearLeftVisionData === '-' && row.wearRightVisionData === '-') {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.wearLeftVisionData) {
          left = row.wearLeftVisionData
        }
        if (row.wearRightVisionData) {
          right = row.wearRightVisionData
        }
        return right + ' / ' + left
      }
    },
    sizeChange (val) {
      this.show.pageSize = val
      this.getData()
    },
    course () {
      this.dialogVisible = true
    },
    // 年级班级选项修改时获取指定年级班级历史学生数据
    getGreadClass (id) {
      if (id) {
        const data = this.getNewGradeClassList.filter(item => {
          return Number(item.id) === Number(id)
        })[0]
        this.getGreadId = data.gradeId
      }
      this.getSelectData = ''
      this.show = {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
      this.getData()
    },
    // 根据学校获取年级和班级
    getClassGrade () {
      const schoolId = storage.get('schoolId')
      GradeClassService.getNewGradeClass({ schoolId, pageSize: 500, startPage: 1 }).then(res => {
        // console.log('11', res)
        this.getNewGradeClassList = res.data.classVoList.map(item => {
          return {
            id: item.id,
            value: item.id,
            label: item.isClass,
            grade: item.grade,
            gradeId: item.newGradeId

          }
        })
      })
    },
    getNewData () {
      this.getSelectData = ''
      this.show = {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
      if (!storage.get('teacherClassId')) this.getClassId = null
      if (!storage.get('teacherClassId')) this.getGreadId = null
      this.getData()
    },
    geySelect () {
      if (!this.getSelectData) {
        return this.$message({
          type: 'info',
          message: '你暂未输入搜索内容'
        })
      }
      const schoolId = storage.get('schoolId')
      let schoolClassId
      if (storage.get('teacherClassId')) schoolClassId = storage.get('teacherClassId')
      if (this.getClassId) schoolClassId = this.getClassId
      let newGradeId
      if (storage.get('teacherGradeId')) newGradeId = storage.get('teacherGradeId')
      if (this.getGreadId) newGradeId = this.getGreadId
      GradeClassService.searchHistoryStudent({ schoolId, schoolClassId, newGradeId, phoneOrName: this.getSelectData + '' }).then(res => {
        // console.log('res', res)
        this.childrenList = res.data.map(item => {
          for (const key in item) {
            if (item[key] === null) item[key] = '-'
          }
          if (item.birthday) item.birthday = newDate.formatDate(new Date(Number(item.birthday)))
          item.state = item.visionIsNormal === 0 ? '正常' : '异常'
          if (item.schoolStatus !== '-') {
            switch (Number(item.schoolStatus)) {
              case 0:
                item.schoolStatus = '在读'
                break
              case 1:
                item.schoolStatus = '毕业'
                break
              case 2:
                item.schoolStatus = '离校'
                break
            }
          }
          return item
        })
        this.show.totalPage = 0
      })
    },
    editClass (value) {
      storage.set('teacherClassId', value)
      this.classList.forEach(item => {
        if (Number(item.classId) === Number(value)) storage.set('teacherGradeId', item.newGradeId)
      })
      this.getSelectData = ''
      this.show = {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
      this.getData()
    },
    getClassList () {
      const teacherClassId = storage.get('teacherClassId')
      if (!teacherClassId) {
        this.showClass = false
        this.getData()
        return
      }
      studentService.getClassId().then(res => {
        this.classList = res.data
        this.getClassId = teacherClassId
        this.getData()
      })
    },
    recoverShow (id) {
      this.showRecoverId = null
      this.recoverClassId = null
      this.$confirm('此操作将永久恢复该学生, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.showRecoverId = id
        this.course()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消恢复'
        })
      })
    },
    recover () {
      const data = this.getNewGradeClassList.filter(item => item.value === this.recoverClassId)[0]
      GradeClassService.recoverStudent({ childrenId: this.showRecoverId, schoolClassId: this.recoverClassId, newGradeId: data.gradeId }).then(res => {
        // console.log('res', res)
        this.$message({
          message: '恢复成功',
          type: 'success'
        })
        this.showRecoverId = null
        this.recoverClassId = null
        this.dialogVisible = false
        this.getData()
      })
    },
    editStartPage (index) {
      this.show.startPage = index
      this.getData()
    },
    getData () {
      const schoolId = storage.get('schoolId')
      const teacherClassId = storage.get('teacherClassId')
      const data = this.show
      data.schoolId = schoolId
      if (teacherClassId) data.schoolClassId = teacherClassId
      if (this.getClassId) data.schoolClassId = this.getClassId
      GradeClassService.getHistoryStudent(data).then(res => {
        // console.log('res1', res)
        this.childrenList = res.data.list.map(item => {
          // console.log('item', item)
          if (item.birthday !== '-') {
            item.birthday = newDate.formatDate(new Date(Number(item.birthday)))
          }
          // console.log('birthday', item.birthday)
          for (const key in item) {
            if (item[key] === null || !item[key]) item[key] = '-'
          }
          item.remark = item.remark === '' ? '-' : item.remark
          item.state = item.visionIsNormal === 0 ? '正常' : '异常'
          if (item.schoolStatus !== '-') {
            switch (Number(item.schoolStatus)) {
              case 0:
                item.schoolStatus = '在读'
                break
              case 1:
                item.schoolStatus = '毕业'
                break
              case 2:
                item.schoolStatus = '离校'
                break
            }
          }
          return item
        })
        this.show.totalPage = res.data.totalPage
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  min-height: 100%;
  background-color: #fff;
  padding: 0 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .seek-container{
      display: flex;
      align-items: center;
      .seek-section{
        border: 1px solid #34BBEC;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        color: #333;
        /deep/.el-input__inner{
          border: none !important;
          padding: 0 10px !important;
        }
        .el-icon-search{
          color: #999999;
        }
      }
      .seek-button{
        width: 80px;
        background-color: #34BBEC;
        margin-left: 20px;
        border-radius: 20px;
        color: #fff;
      }
    }
    .add-container{
      .addUser-button{
        background-color: #34BBEC;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
  .body-container{
    padding: 20px 0;
    flex: 1;
  }
  .footer-container{
    display: flex;
    width: 100%;
    padding: 33px 0;
    .el-button{
      margin-right: 160px;
    }
  }
}
.select-section{
  display: flex;
  .el-input{
    width: 200px;
    margin: 0 5px;
  }
  .el-button{
    margin: 0 5px;
  }
}
/deep/.el-table--enable-row-transition .el-table__body td.el-table__cell{
  text-align: center;
}
</style>
